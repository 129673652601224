import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function CasagrandReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Casagrand Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Casagrand Builder Private Limited is a real estate enterprise committed to building aspirations and delivering value. In the last eighteen years, we have developed over 36 million sqft of prime residential real estate across Chennai, Bengaluru, and Coimbatore. Over 27,000 happy families across 140+ landmark properties stand testimony to our commitment.</p>

                <p className="AboutExpoPara">In the eighteen years of our journey, we at Casagrand are all set to progress further forward with projects worth over ₹8000 crores in the pipeline.</p>
            </p>

            {isShowMore && (
                <p className="mb-0">
                    <h6>Vision</h6>
                    <p className="AboutExpoPara">We will give the customer who has trusted us with their hard-earned money the most exceptional home with a lifestyle that nobody else has given, and for which the customer will bless us.</p>
                    <h6>Mission</h6> 
                    <p className="AboutExpoPara">●	To be a 6800crs turnover company by 2025 (with 80% contribution from residential)</p>
                    <p className="AboutExpoPara">●	To be amongst the top 5 builders in the country in turnover</p>
                    <p className="AboutExpoPara">●	To be present in 1 international market and 5 big metros in India by 2025</p>
                    <p className="AboutExpoPara">●	To be the most preferred employer in South India</p>
                    <p className="AboutExpoPara">●	To be one of the Most Trusted Brands in India by 2025</p>

                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default CasagrandReadMore;